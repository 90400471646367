import React from 'react';

import { Flex } from '../Core/Flex';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';
import ThemedButton from '../ThemedButton';

interface IPathologieEntryProps {
  categoryName: string;
  ctaLabel: string;
  color?: string | null;
  title: string;
  url: string;
}

const PathologieEntry: FCWithClassName<IPathologieEntryProps> = (props) => {
  const {
    categoryName,
    ctaLabel,
    color = 'white',
    title,
    url,
    ...rest
  } = props;
  return (
    <StyledLink
      to={url}
      className="no-underline block w-[45%] lg:w-[300px] max-w-full h-[150px] lg:h-[320px] mb-4 lg:mb-8"
    >
      <Flex
        direction="column"
        justify="center"
        align="center"
        style={{ background: color }}
        className="relative h-full px-4 py-12"
        {...rest}
      >
        <Text
          variant={TEXT_STYLES.H3}
          className="absolute top-[-0.5em] left-1/2 -translate-x-1/2 text-center !text-4 lg:!text-6 font-bold"
        >
          {categoryName}
        </Text>
        <Text variant={TEXT_STYLES.H5} className="font-bold text-center">
          {title}
        </Text>
        <ThemedButton className="hidden md:block overlap-centered bottom-[-30px] whitespace-nowrap">
          {ctaLabel}
        </ThemedButton>
      </Flex>
    </StyledLink>
  );
};

export default PathologieEntry;
