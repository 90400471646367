import React from 'react';

import { DEFAULT_LANGUAGE } from '../../constants/global';
import { FILTERS_NO_RESULTS } from '../../constants/messages';
import { IWordpressPathologieEntry } from '../../types/wordpress/pathologie';
import { IWordpressPostEntry } from '../../types/wordpress/post';
import { Flex } from '../Core/Flex';
import PathologieEntry from '../PathologieEntry/PathologieEntry';
import SectionWithTitle from '../SectionWithTitle';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IPathologiesSectionProps {
  currentLanguageCode: Partial<CountryCode>;
  description: string | undefined;
  displayedCategoriesSlugs?: string[];
  noPostsMessage?: string;
  title: string | undefined;
  posts?: (IWordpressPostEntry | null)[];
  pathologies?: Queries.PagePathologieQuery['wpPathologie']['zonesCorps']['nodes'][number]['contentNodes']['nodes'];
  readMoreLabel?: string;
}

const PathologiesSection: FCWithClassName<IPathologiesSectionProps> = (
  props
) => {
  const {
    currentLanguageCode,
    description,
    displayedCategoriesSlugs,
    noPostsMessage,
    posts,
    pathologies,
    title,
    ...rest
  } = props;

  const getCategoryMatchingActiveFilters = ({
    postCategories,
    activeFilters,
    zonesCorps,
  }: {
    activeFilters: string[] | undefined;
    postCategories?: IWordpressPostEntry['categories']['nodes'];
    zonesCorps?: IWordpressPathologieEntry['zonesCorps']['nodes'];
  }) => {
    const categories = postCategories ?? zonesCorps;
    if (!activeFilters?.length) return categories?.[0];
    const categoryMatchingActiveFilter = categories.find((category) =>
      activeFilters.includes(category.slug)
    );
    return categoryMatchingActiveFilter;
  };
  const displayPostEntryForPathologie = (
    pathologie: IWordpressPathologieEntry
  ) => {
    const categoryMatchingActiveFilters =
      pathologie?.zonesCorps.nodes &&
      getCategoryMatchingActiveFilters({
        activeFilters: displayedCategoriesSlugs,
        zonesCorps: pathologie?.zonesCorps.nodes,
      });
    if (!pathologie || !categoryMatchingActiveFilters) return undefined;
    return (
      <PathologieEntry
        categoryName={categoryMatchingActiveFilters.name}
        color={pathologie.zonesCorps.nodes[0]?.categoryInformations.couleur}
        ctaLabel={props.readMoreLabel ?? 'Lire la suite'} // todo : fix
        key={pathologie.uri}
        title={pathologie.title}
        url={pathologie.uri}
      />
    );
  };
  return (
    <SectionWithTitle heading={title} text={description} {...rest}>
      {posts?.length === 0 && (
        <Text variant={TEXT_STYLES.H5} className="pt-12 mx-auto text-center">
          {noPostsMessage ??
            FILTERS_NO_RESULTS[currentLanguageCode] ??
            FILTERS_NO_RESULTS[DEFAULT_LANGUAGE]}
        </Text>
      )}
      <Flex
        direction="row"
        justify="space-around"
        className="flex-wrap w-full gap-8 lg:gap-x-12 lg:gap-y-20"
      >
        {pathologies?.map(displayPostEntryForPathologie)}
      </Flex>
    </SectionWithTitle>
  );
};

export default PathologiesSection;
