import './Post.css';

import { graphql, PageProps } from 'gatsby';
import React, { useRef } from 'react';

import Author from '../../components/Author/Author';
import { Box, Stack } from '../../components/Core';
import Image from '../../components/Image';
import Layout from '../../components/Layout';
import PathologiesSection from '../../components/PathologiesSection';
import SectionWithTitle from '../../components/SectionWithTitle';
import SEO from '../../components/SEO';
import SocialShareIcons from '../../components/SocialShareIcons';
import StyledLink from '../../components/StyledLink';
import TeamMember from '../../components/TeamMember';
import Text from '../../components/Text';
import { TEXT_STYLES } from '../../components/Text/constants';
import ThemedButton from '../../components/ThemedButton';
import useReplaceWPGalleryLinks from '../../hooks/useReplaceWPGalleryLinks';
import {
  IWordpressFeaturedImage,
  IWordpressImage,
} from '../../types/wordpress/blocks';
import { INodeCommonContent } from '../../types/wordpress/node';
import { getCorrectLink } from '../../utils/links';
import {
  createImageWithArtDirection,
  serializeImageForImageComponent,
} from '../../utils/serializeImageForImageComponent';
import NavSummary from '../components/Navs/NavSummary';

interface IWordpressAuthor {
  name: string;
  slug: string;
}

interface PostAuthor {
  id: string;
  staffInformations: {
    nom: string;
    photo: IWordpressImage;
    email: string;
    description: string;
    gsm: string;
    poste: {
      id: string;
      title: string;
    }[];
    prenom: string;
  };
}

export interface IPathologie extends INodeCommonContent {
  zonesCorps: {
    nodes: {
      categoryInformations: {
        couleur: string | null;
      };
      name: string;
      slug: string;
      id: string;
      contentNodes: {
        nodes: {
          title: string;
          slug: string;
        }[];
      };
    }[];
  };
  author: IWordpressAuthor;
  content: string;
  postId: string;
  excerpt: string | undefined;
  featuredImage: IWordpressFeaturedImage | undefined;
  informationsPathologie?: {
    sectionHero: {
      imageMobile: IWordpressImage | null;
      titre: string | null;
    };
    auteur: {
      membre: PostAuthor[];
    };
    services: {
      id: string;
      slug: string;
      title: string;
      informations: { couleur: string };
    }[];
    contenu: string;
    introduction: string;
    youtube?: string;
  };
}

type PathologieTemplate = PageProps<Queries.PagePathologieQuery, IPageContext>;

const PathologieTemplate: FCWithClassName<PathologieTemplate> = (props) => {
  const { data, pageContext } = props;
  const pageData = data.wpPathologie;
  const pageTemplateData =
    'contenuArticle' in data.wpPage.template
      ? data.wpPage.template.contenuArticle
      : null;
  if (!pageData || !pageTemplateData || !pageContext.defaultLanguage)
    return null;
  // const sectionArticlesLies = 'sectionArticlesLies' in data.wpPage.template ? data.wpPage.template. : null
  const postContentRef = useReplaceWPGalleryLinks();

  const postRef = useRef(null);

  const post = pageData;
  const relatedPathologies = post.zonesCorps.nodes[0]?.contentNodes.nodes;
  const filteredRelatedPathologies = relatedPathologies?.filter((pathologie) =>
    'title' in pathologie ? pathologie.title !== post.title : false
  );
  const relatedPathologiesWithZoneCorps = filteredRelatedPathologies?.map(
    (pathologie) => ({ ...pathologie, zonesCorps: post.zonesCorps })
  );
  const imageWithArtDirection = createImageWithArtDirection({
    desktopImage: post.featuredImage?.node,
    mobileImage: post.informationsPathologie?.sectionHero.imageMobile,
  });
  const authors = post.informationsPathologie?.auteur.membre;
  const formattedAuthorsNames = authors?.map(
    (author) =>
      `${author.staffInformations.prenom} ${author.staffInformations.nom}`
  );
  const formatAuthorsCredits = (authorsNames: string[] | undefined) => {
    const preposition = pageTemplateData?.sectionAuteur.preposition;
    if (!authorsNames?.length) return `${preposition} Uperform`;
    if (authorsNames.length === 1) return `${preposition} ${authorsNames[0]}`;
    const firstAuthorsNames = authorsNames.slice(0, authorsNames.length - 1);
    const lastAuthorName = authorsNames[authorsNames.length - 1];
    const authorsNameList = `${firstAuthorsNames.join(
      ','
    )} & ${lastAuthorName}`;
    return `${preposition} ${authorsNameList}`;
  };
  const formattedAuthorCredits = formatAuthorsCredits(formattedAuthorsNames);
  const authorsComponents = authors?.map((authorData) => {
    const { description, prenom, gsm, photo, email, nom } =
      authorData.staffInformations;
    const image = serializeImageForImageComponent({
      image: photo,
    });
    return (
      <Stack justify="center" className="space-y-12" key={email}>
        <Author
          className="hidden lg:flex"
          description={description}
          firstName={prenom}
          gsm={gsm}
          image={image}
          mail={email}
          lastName={nom}
        />
        <TeamMember
          className="lg:hidden"
          description={description}
          firstName={prenom}
          gsm={gsm}
          image={image}
          mail={email}
          lastName={nom}
          languages={authorData.staffInformations.languages}
          textLink={authorData.staffInformations.textLink}
          linkType={authorData.staffInformations.linkType}
          externalLink={authorData.staffInformations.externalLink}
          phoneLink={authorData.staffInformations.phoneLink}
        />
      </Stack>
    );
  });
  return (
    <Layout
      currentLanguage={pageContext.currentLanguage}
      currentPath={pageData.link}
      menuData={pageContext.menus}
      siteSettings={pageContext.siteSettings}
      submenuItems={pageContext.submenuItems}
      translations={pageData.translations}
    >
      <SEO pageData={pageData} />
      <Stack className="space-y-gap mt-gap" ref={postRef}>
        <SectionWithTitle contentAlignment="center">
          <Stack align="center" className="w-full space-y-12">
            <Text
              variant={TEXT_STYLES.H5}
              className="uppercase text-primaryMain"
            >
              {post.zonesCorps.nodes[0]?.name}
            </Text>
            <Text
              variant={TEXT_STYLES.H1}
              className="text-center !normal-case !text-5 md:!text-6"
            >
              {post.informationsPathologie?.sectionHero.titre}
            </Text>
            <StyledLink anchorId="#author" variant="internalLink">
              <Text>{formattedAuthorCredits}</Text>
            </StyledLink>
            <SocialShareIcons location={props.location.href} />

            {post.featuredImage && !post.informationsPathologie?.youtube && (
              <div className="w-full aspect-h-6 aspect-w-4 sm:aspect-w-6 md:aspect-w-8 xl:aspect-w-14">
                <Image
                  image={serializeImageForImageComponent({
                    image: imageWithArtDirection,
                  })}
                />
              </div>
            )}

            <Box className="relative w-full max-w-3xl">
              <Text
                className="post--intro font-bold !text-lg sm:!text-xl"
                style={
                  {
                    '--post-intro-background':
                      post.zonesCorps.nodes[0]?.categoryInformations.couleur,
                  } as React.CSSProperties
                }
              >
                {post.informationsPathologie?.introduction}
              </Text>
            </Box>

            <NavSummary postRef={postRef} />

            {post.informationsPathologie?.youtube && (
              <div className="w-full aspect-h-6 aspect-w-4 sm:aspect-w-6 md:aspect-w-8 xl:aspect-w-14">
                <iframe
                  src={`https://youtube.com/embed/${post.informationsPathologie?.youtube}`}
                  allowFullScreen
                />
              </div>
            )}

            <Box
              className="w-full max-w-3xl text-lg prose prose-post"
              ref={postContentRef}
              dangerouslySetInnerHTML={{
                __html: post.informationsPathologie?.contenu,
              }}
            />
            <SocialShareIcons location={props.location.href} />
          </Stack>
        </SectionWithTitle>
        {authors?.length && (
          <SectionWithTitle
            heading={pageTemplateData?.sectionAuteur.titreSection}
            contentAlignment="center"
            id="author"
          >
            {authorsComponents}
          </SectionWithTitle>
        )}
        {relatedPathologiesWithZoneCorps?.length > 0 && (
          <PathologiesSection
            currentLanguageCode={props.pageContext.currentLanguage.code}
            description=""
            pathologies={relatedPathologiesWithZoneCorps}
            title=""
            readMoreLabel={
              data.allWpConfiguration.nodes[0]?.configuration?.textes?.lirePlus
            }
          />
        )}
        <StyledLink
          className="mx-auto"
          to={
            getCorrectLink(
              pageTemplateData?.sectionArticlesLies.blogLink.url ?? null
            ).to
          }
        >
          <ThemedButton>
            {pageTemplateData?.sectionArticlesLies.blogLink.title}
          </ThemedButton>
        </StyledLink>
      </Stack>
    </Layout>
  );
};

export default PathologieTemplate;

export const query = graphql`
  query PagePathologie($id: String, $currentLanguageSlug: String) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wpPathologie(id: { eq: $id }) {
      featuredImage {
        node {
          ...MediaItemFragment
          localFile {
            ...HeroImage
          }
        }
      }
      language {
        locale
        slug
      }
      link
      seo {
        ...SEOFragment
      }
      slug
      title
      translations {
        language {
          code
          locale
        }
        uri
      }
      zonesCorps {
        nodes {
          categoryInformations {
            couleur
          }
          name
          slug
          id
          contentNodes {
            nodes {
              ... on WpPathologie {
                title
                uri
                slug
              }
            }
          }
        }
      }
      informationsPathologie {
        auteur {
          membre {
            ... on WpStaff {
              id
              staffInformations {
                nom
                photo {
                  ...MediaItemFragment
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 240
                        breakpoints: [240, 480]

                        layout: CONSTRAINED
                      )
                    }
                    publicURL
                  }
                }
                email
                description
                gsm
                languages
                textLink
                linkType
                externalLink
                phoneLink
                poste {
                  ... on WpService {
                    id
                    title
                  }
                }
                prenom
              }
            }
          }
        }
        sectionHero {
          imageMobile {
            ...MediaItemFragment
            localFile {
              ...MobileHeroImage
            }
            publicUrl
          }
          titre
        }
        services {
          ... on WpService {
            id
            slug
            title
            informationsService {
              couleur
            }
          }
        }
        contenu
        introduction
        youtube
      }
      author {
        node {
          name
          slug
        }
      }
    }
    wpPage(
      language: { slug: { eq: $currentLanguageSlug } }
      template: { templateName: { eq: "Page Pathologie" } }
    ) {
      template {
        ... on WpTemplate_PagePathologie {
          templateName
          contenuArticle {
            sectionArticlesLies {
              blogLink {
                url
                title
              }
            }
            sectionAuteur {
              preposition
              titreSection
            }
          }
        }
      }
    }
    allWpConfiguration(
      filter: { language: { slug: { eq: $currentLanguageSlug } } }
    ) {
      nodes {
        configuration {
          textes {
            lirePlus
          }
        }
      }
    }
  }
`;
