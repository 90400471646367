import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { FiPhone } from '@react-icons/all-files/fi/FiPhone';
import React from 'react';

import { tw } from '../../utils/tailwind';
import { Box } from '../Core';
import { Flex } from '../Core/Flex';
import IconWithText from '../IconWithText';
import Image from '../Image';
import { IImage } from '../Image/Image';
import StyledLink from '../StyledLink';
import Text from '../Text';
import { TEXT_STYLES } from '../Text/constants';

interface IAuthorProps {
  image: IImage | null;
  firstName: string;
  gsm?: string;
  lastName: string;
  position?: string;
  mail?: string;
  description?: string;
}

const Author: FCWithClassName<IAuthorProps> = (props) => {
  const {
    className,
    description,
    firstName,
    gsm,
    image,
    lastName,
    mail,
    position,
    ...rest
  } = props;
  return (
    <Flex className={tw('relative', className)} {...rest}>
      <Box className="absolute w-48 my-5 l-0">
        <Image image={image} className="aspect-w-8 aspect-h-12 max-w-[200px]" />
      </Box>
      <Flex
        direction="column"
        className="w-full max-w-4xl py-8 pl-48 pr-8 ml-8 bg-primaryMain"
      >
        <Text variant={TEXT_STYLES.H3}>{`${`${firstName} ${lastName}`}`}</Text>
        <Text
          variant={TEXT_STYLES.H4}
          className="!text-3xl !font-medium"
        >
          {position}
        </Text>
        <Text
          variant={TEXT_STYLES.P}
          className="pt-6 lg:!text-xl w-[105%]"
        >
          {description}
        </Text>
        <address>
          {mail && (
            <StyledLink
              shouldObfuscate
              isEmail
              to={mail}
              className="text-text"
            >
              <IconWithText
                description={mail}
                icon={FiMail}
                isInline
                iconClassName="w-8 h-8"
              />
            </StyledLink>
          )}
          {gsm && (
            <StyledLink
              shouldObfuscate
              isPhone
              to={gsm}
              className="text-text"
            >
              <IconWithText
                description={gsm}
                icon={FiPhone}
                iconClassName="w-8 h-8"
                isInline
              />
            </StyledLink>
          )}
        </address>
      </Flex>
    </Flex>
  );
};

export default Author;
