import React, { useEffect } from 'react';

interface NavSummaryProps {
  postRef: React.RefObject<HTMLDivElement>;
}

const NavSummary = ({ postRef }: NavSummaryProps) => {
  const [links, setLinks] = React.useState([]);

  const slugify = (text: string) => {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  };

  useEffect(() => {
    if (!postRef.current) return;

    const headings =
      postRef.current.querySelectorAll<HTMLHeadingElement>('.prose-post h2');

    const links = Array.from(headings).map((heading) => {
      heading.id = slugify(heading.textContent);

      return {
        id: slugify(heading.textContent),
        title: heading.textContent,
      };
    });

    setLinks(links);
  }, []);

  if (!links.length) return null;

  return (
    <div className="w-full max-w-3xl text-lg prose prose-post">
      <ul>
        {links.map((link) => (
          <li key={link.id}>
            <a
              href={`#${link.id}`}
              onClick={(e) => {
                const element = document.getElementById(link.id);

                if (element) {
                  e.preventDefault();

                  // Scroll into view with smooth and padding
                  element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }}
            >
              {link.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NavSummary;
